import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';
import { AutoComplete } from '@mescius/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_MAX_10_CHARACTOR,
  MESSAGE_IS_NUMBER,
  ORGANIZATIONAL_DIVISION
}
from '@/constants/registerData'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'

import { getListDbAddNew, getDbAddNewInObject  } from '@/concerns/utils/master-or-customize-db'
import { formatValue, $_helper_isNumberType, math, formatBigNumber, formatEmission25Number, validateNumberFieldBySetting, $_helper_isNumberType_bySetting, formatNumberBySetting } from './wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import { formatNumberRealNum } from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { handleLostFocus } from '@/utils/csvImportHandler'
import { handleLostFocusBasicUnitPulldown, handleBasicUnitDroppedDownChanged } from '@/utils/registerData';
import { dbIdeaObj } from '@/api/ideaDbSource';
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';

import { DB_TYPE } from '@/constants/dbType';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    fuel: null,
    target_model: null,
    distance: null,
    fuel_efficiency: null,
    unit: null,
    value: null,
    wsu_unit: null,
    wsu_value: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    fuel_efficiency_source: null,
    distance_source: null,
    db_relation: null
  }
}

const calcEmissions = (wsu_value, distance, fuel_efficiency) => {
  if(fuel_efficiency === '0') {
    return 0
  }
  if($_helper_isNumberType_bySetting(distance) && $_helper_isNumberType_bySetting(wsu_value)&& $_helper_isNumberType_bySetting(fuel_efficiency)){
    const valDistance = math.bignumber(formatNumberBySetting(distance));
    const valWsu = math.bignumber(formatNumberBySetting(wsu_value));
    const valEfficiency = math.bignumber(formatNumberBySetting(fuel_efficiency));
    return  formatBigNumber(math.evaluate(`${valDistance.toString()} * ${valWsu.toString()} / ${valEfficiency.toString()} / 1000`), 50)
  }
  return 0;
}

const validateNumberField = (input, propName = '') => {
  if (input) {
    if(!$_helper_isNumberType(input)){
      return MESSAGE_IS_NUMBER; 
    }
    const number10Char = formatValue(input).includes('-') ? 11 : 10
    if (formatValue(input).length > number10Char && propName === 'fuel_efficiency') {
      return MESSAGE_MAX_10_CHARACTOR;
    }
    //remove check negative number
    // if ($_helper_isNegativeNumber(input)) {
    //   return MESSAGE_POSITIVE_NUMBER;
    // }
    const numberValidate = formatValue(input).includes('-') ? 26 : 25
    if (formatValue(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
  return null;
}


const notNullList = [
  'organizational_division',
  'company_name',
  'distance',
  'fuel_efficiency',
  'fuel',
  'wsu_value',
  'wsu_unit',
]

const notNullListDbSource = [
  'organizational_division',
  'company_name',
  'distance',
  'fuel_efficiency',
]

const max25Charactor = [
  'distance',
  'wsu_unit',
  // 'wsu_value'
]

const max10Charactor = [
  'fuel_efficiency',
]

const max128Charactor = [
  // 'company_name',
  // 'business_name',
  'fuel',
  'memo_1',
  'memo_2',
  'distance_source',
  'fuel_efficiency_source',
  'target_model',
]

const numberField = ['fuel_efficiency', 'distance']

const isValidData = (item) => {
  if (notNullList.some(field => !item[field] && item[field] !== 0)) {
    return false
  }
  if (max128Charactor.some(field => item[field]?.length > 128)) {
    return false
  }
  if (max25Charactor.some(field => item[field]?.length > 20)) {
    return false
  }
  if (numberField.some(field => validateNumberField(String(item[field])) !== null)) {
    return false
  }
  if (item?.fuel_efficiency > 9999999999) {
    return false
  }
  return true
}

let isReverted = false;
let searchID = null;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const autoBindingDbSource = dataBinding => {
  const { s, binding, row, cellData, currentItem, dataStore, dbAddNewInObject } = dataBinding
  if (binding === 'distance' || binding === 'fuel_efficiency') {
    s.deferUpdate(() => {
      currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.fuel_efficiency);
    })
  }

  if (binding === 'fuel') {
    let dbItem = dbAddNewInObject[cellData] || dataStore[cellData]
    if (searchID && !dbItem) {
      dbItem =  dataStore[searchID] || dbAddNewInObject[searchID]
    }
    const { SERVICE_LINK, IDEA } = DB_TYPE;
      currentItem.idDbExternal = ![SERVICE_LINK, IDEA].includes(dbItem?.type) ? null : dbItem?.id
    s.deferUpdate(() => {
      if (dbItem) {
        if(dbItem?.type === SERVICE_LINK) {
          currentItem['detailExternalProductCode'] = currentItem.idDbExternal
          currentItem['distance'] = dbItem.numberValue
        } else {
          currentItem['detailExternalProductCode'] = null
        }
        currentItem['fuel'] = dbItem.id;
        currentItem['wsu_value'] = formatNumberRealNum(dbItem.value_source);
        currentItem['wsu_unit'] = dbItem.unit_source;
        currentItem['emissions'] = calcEmissions(dbItem.value_source, currentItem.distance, currentItem.fuel_efficiency);
      } else {
        currentItem['fuel'] = null;
        currentItem['wsu_value'] = null;
        currentItem['wsu_unit'] = null;
        currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.fuel_efficiency);
      }
    })
  }
}
let filterIndex = {};
const parternScoped7Method5 = {
  initializeGrid(dataProps) {
    const { flexgrid, itemCount, branchData = {}, getNewBbStore } =  dataProps
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }

      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

      
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

      const dbStore = getNewBbStore()
      const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(dbStore.dbExternalAdded))
      const dataStore = dbStore.getDbCustomizeOrDbMasterById

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted

          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (['distance', 'fuel_efficiency', 'fuel'].includes(s.columns[colIndex].binding)) {
            const cellData = s.getCellData(rowIndex, colIndex, false)
            const currentItem = source[rowIndex] || {}
            const binding = s.columns[colIndex].binding
            const dataBinding = { s, binding, row: rowIndex, cellData, currentItem, dataStore, dbAddNewInObject }
            //autoBindingDbSource(dataBinding)
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}
      const binding = column.binding
      const dbStore = getNewBbStore()
      const dataStore = dbStore.getDbCustomizeOrDbMasterById;
      const item = s.rows[e.row].dataItem;
      const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(dbStore.dbExternalAdded))
      const dataBinding = { s, binding, row, cellData, currentItem, dataStore, dbAddNewInObject }
      //autoBindingDbSource(dataBinding)

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item = s.rows[e.row].dataItem;

      if (
        column.binding === 'unit' ||
        column.binding === 'wsu_unit' ||
        column.binding === 'wsu_value' ||
        column.binding === 'emissions'
      ) {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[Number(item?.clientRowId) - 1 || row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
    });
    
  },

 header(dataProps) {
    const { 
      registerData,
      listLayout,
      branchData,
      externalSourcePopup,
      attachFilePopup,
      serviceLinkPopup,
      dbStore,
      grid,
      isRemoveStatusColumn,
      basicUnitSearchPopup,
      uniqueOriginalDb,
      isPastingDbIdea = false,
      basicUnitData,
      typeDbItemAddNew,
      uniqueOriginalAllDbUnit
    } = dataProps

    let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    const listDbAddNew = getListDbAddNew(dbStore.dbExternalAdded) // get all db add new
    const dbList = uniqueOriginalAllDbUnit?.length > 0 ? uniqueOriginalAllDbUnit : [ ...uniqueOriginalDb, ...listDbAddNew ]

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 400,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_target_model'), // Loại xe thuộc đối tượng
        binding: 'target_model',
        minWidth: 95,
        maxWidth: 480,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true,
      },
      {
        header: i18n.t('56_pattern.table_memo_1'),
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_memo_2'),
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_exhaust_original'),
        align: 'center',
        columns: [
          {
            header      : i18n.t('basic_unit_library.label_search_basic_unit'),
            binding     : "basic_unit_search",
            maxWidth    : getWidthOfSearchButton(i18n.locale),  
            minWidth    : getWidthOfSearchButton(i18n.locale),  
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            cssClassAll : "search-detail hide-filter",
            cellTemplate: CellMaker.makeButton({
              text : 
                `<div class="search-container">
                  ${i18n.t('register_data.button_search')}
                  <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
                </div>`,
              click: (e, ctx) => basicUnitSearchPopup(ctx, 'wsu_source')
            }),
          },
          {
            header: i18n.t('basic_unit_library.basic_unit_name'),
            binding: 'fuel',
            minWidth: 300,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(dbList, 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: dbList,
              isDroppedDownChanged: (sender) => {
                handleBasicUnitDroppedDownChanged({sender, dataProps})
              },
              lostFocus: (sender, ctx) => {
                handleLostFocusBasicUnitPulldown({sender, ctx, dataProps});
                searchID = handleLostFocus({ ctx, sender, dbStore, registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, grid, isRemoveStatusColumn, uniqueOriginalDb, basicUnitData }, parternScoped7Method5.header)
              },
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            }),
          },
          {
            header: i18n.t('56_pattern.table_wsu_value'), // Đơn vị nguồn thải
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClass: 'auto-increment',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_wsu_unit'), // Đơn vị nguồn thải (unit)
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            cssClassAll: 'required-field',
          }
        ],
      },
      {
        header: i18n.t('56_pattern.table_transportation_data'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_transport_distance'), //
            binding: 'distance',
            minWidth: 142,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_distance_source'), //source
            binding: 'distance_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_fuel_economy_data'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_fuel_efficiency'), //
            binding: 'fuel_efficiency',
            minWidth: 125,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_fuel_efficiency_source'), //source
            binding: 'fuel_efficiency_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        inputType: 'float',
        dataType: 'Number',
      },
      {
        header      : i18n.t('56_pattern.table_attach_file'),
        binding     : "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired  : false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        } 
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore,
      itemAdded
    } = dataProps
    let databaseTypeObject = {};
    const wsuSource = dataTable.fuel;
    if(itemAdded) {
      databaseTypeObject = itemAdded
    } else {
      databaseTypeObject = dbStore.getDbCustomizeOrDbMasterById[wsuSource] || {};
      dataTable.idDbExternal = null
      dataTable.db_relation = null
    }
    dataTable.fuel = databaseTypeObject?.id || null;
    dataTable.wsu_source = databaseTypeObject.source || null;
    dataTable.wsu_unit = databaseTypeObject.unit_source || null;
    dataTable.wsu_value = formatNumberRealNum(databaseTypeObject?.value_source) || formatNumberRealNum(databaseTypeObject.wsu_value) || null;
    if(!isGetBranchDetail) return
    const emissions = calcEmissions(dataTable.wsu_value, dataTable.distance, dataTable.fuel_efficiency);

    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};

    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      target_model: dataTable.target_model || null,
      distance: isSaveTemplate ? '' :formatNumberBySetting(dataTable.distance) === 0 ? '0' : formatNumberBySetting(dataTable.distance) || null,
      fuel_efficiency: isSaveTemplate ? '' :  formatNumberBySetting(dataTable.fuel_efficiency) === 0 ? '0' : formatNumberBySetting(dataTable.fuel_efficiency) || null,
      distance_source: dataTable.distance_source || null,
      fuel_efficiency_source: dataTable.fuel_efficiency_source || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      type: databaseTypeObject.type,
      db_customize_id: databaseTypeObject.origin_id || null,
      db_master_id: databaseTypeObject.origin_id || null,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      db_relation: dataTable.db_relation ||  null,
       evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };
      
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    } else {
      dataTableItemCustom.db_master_id = null
    }
    if(dataTableItemCustom.type === DB_TYPE.SERVICE_LINK || dataTableItemCustom.type === DB_TYPE.IDEA || dataTable.idDbExternal) {
      const dbRelationKey = getDbRelationKeysByType(dataTableItemCustom.type);
      dataTableItemCustom.db_relation = {
        [dbRelationKey]: databaseTypeObject.origin_id
      }
      // dataTableItemCustom.type = 2
      dataTableItemCustom.db_customize_id = null
      dataTableItemCustom.db_master_id = null
    }

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
      'company_name',
      'fuel',
      'target_model',
      'memo_1',
      'memo_2',
      'distance',
      'distance_source',
      'fuel_efficiency',
      'fuel_efficiency_source',
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;

    let isDataDbIdea = item.fuel && dbStore.getDbCustomizeOrDbMasterById[item.fuel]?.type === 2
    
    const listItemvalidate = item.db_relation || isDataDbIdea ? notNullListDbSource : notNullList

    if (listItemvalidate.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
    }

    if (max25Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if (max10Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 10);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return i18n.t('new_validate.error_mapping_layer', { listLayer: itemsInViewError.map(item => item)?.join('/') })
      }
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'wsu_value',
      'wsu_unit',
      'energy_type',
      'unit',
      'fuel',
      'target_model',
      'memo_1',
      'memo_2',
      'distance',
      'distance_source',
      'fuel_efficiency',
      'fuel_efficiency_source',
      'attach_file',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'wsu_value',
      'wsu_unit',
      'energy_type',
      'unit',
      ...bindingLayout
    ];
  },

  autoFields: [
    'fuel',
    'wsu_value',
    'wsu_unit',
  ],

  patternUrl: '/pattern-s3-c6/3',
  valueSource: 'fuel',

  calcEmissions(rowData) {
    return calcEmissions(rowData.wsu_value, rowData.distance, rowData.fuel_efficiency)
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);

    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn]
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
  bindingValueColumn: 'distance',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['distance', 'wsu_value', 'fuel_efficiency'],
  isUseBasicUnit: true,
  getAutoBindingDbSource(dataProps) {
    return autoBindingDbSource(dataProps);
  }
};

export default parternScoped7Method5;
